.portfolio {
  padding: 4rem 0;
  overflow: hidden;
}

.portfolio .title {
  text-align: center;
  padding: 2rem 0;
}

.portfolio .links {
  margin: 1rem 0 0;
}

.portfolio .links ul {
  justify-content: center;
  flex-wrap: wrap;
}

.projects {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  padding-top: 4rem;
}

.project {
  cursor: pointer;
  background: var(--primary-color);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: none;
}

.project .item {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.project .info {
  position: relative;
  min-width: 270px;
  z-index: 1;
  display: block;
  flex-grow: 1;
}

.project .buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1rem;
  justify-content: center;
}

.project .buttons div {
  width: 48%;
  padding: 0 0.5rem;
}

.project .buttons .inactive a {
  cursor: default;
  opacity: 0.6;
}

.project:hover {
  box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.3);
}

.project .thumbnail {
  background: #000;
  height: 200px;
  overflow: hidden;
}

.project .thumbnail img {
  opacity: 0.85;
}

.project:hover .thumbnail img {
  transform: scale(1.1);
  opacity: 1;
}

.project .thumbnail .date {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  background: #e74c3c;
  width: 55px;
  height: 55px;
  padding: 15px 0;
  font-size: 16px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  box-sizing: border-box;
}

.project .thumbnail img {
  display: block;
  min-width: 100%;
  min-height: 100%;
  object-fit: contain;
  transition: transform 0.3s linear 0s;
}

.project .content {
  position: relative;
  background: var(--primary-color);
  width: 100%;
  padding: 30px 30px 15px 30px;
  transition: transform 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
}

.project .content .status {
  position: absolute;
  top: -20px;
  left: 0;
  padding: 10px 15px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.project .content .status-active {
  background: #e74c3c;
}

.project .content .status-inactive {
  background: #454545;
}

.project .content .title {
  margin: 0;
  padding: 0 0 10px;
  font-size: 26px;
  font-weight: 700;
  text-align: start;
}

.project .content .description {
  color: var(--text-primary-color);
  font-size: 1.6rem;
  text-align: start;
  font-weight: 200;
  line-height: 2.2rem;
  overflow: hidden;
  transition: transform 0.5s;
}

.project:hover .content {
  transform: translateY(-20px);
}

/* Tablet */
@media (min-width: 640px) {
  .projects {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Desktop */
@media (min-width: 1400px) {
  .projects {
    grid-template-columns: repeat(3, 1fr);
  }
}
