@import "../..//styles/animations.css";

.contact-section {
  min-height: 100vh;
  background: var(--accent-color);
}

.profile {
  background-color: var(--background-color);
}

.profile .container {
  display: flex;
  flex-direction: column;
  gap: 8rem;
}

.profile .info {
  width: 80%;
  margin: 0 auto;
  margin-top: -8rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem 0;
}

.profile .info img {
  width: 100%;
  border-radius: 50%;
  border: 0.5rem solid white;
}

.profile .info a.button {
  min-width: 80%;
}

.profile .skills {
  flex: 1;
  padding: 4rem 0;
}

.profile .skills .title {
  width: 100%;
  text-align: center;
  padding-bottom: 1rem;
  text-shadow: none;
}

.profile .skills .links ul {
  flex-wrap: wrap;
  justify-content: center;
}

.profile .skills .list {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  gap: 1rem;
  padding: 1rem;
}

.profile .skills .list div.visable {
  display: inline-block;
  animation: fadeInUpAnimation 500ms;
  animation-fill-mode: forwards;
}

.profile .skills .list div.gone {
  display: none;
  animation: fadeOutDownAnimation 500ms;
  animation-fill-mode: forwards;
}

@media (min-width: 1280px) {
  .profile .container {
    flex-direction: row;
  }

  .profile .info {
    width: 30%;
  }

  .profile .info svg {
    width: 2rem;
  }

  .profile .skills {
    width: 70%;
  }
}

@keyframes fadeInUpAnimation {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
    display: inline-block;
  }
}

@keyframes fadeOutDownAnimation {
  from {
    opacity: 1;
    transform: translateY(0);
    display: inline-block;
  }

  to {
    opacity: 0;
    transform: translateY(-20px);
    display: none;
  }
}
