footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: var(--text-color);
  background-color: var(--footer-background);
}

footer .content {
  padding: 3rem 0;
}

footer .container {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  justify-content: space-between;
  gap: 4rem;
}

footer .container p {
  font-weight: normal;
  font-size: 1.4rem;
}

footer .container a:link,
footer .container a:visited {
  color: var(--link-secondary-color);
}

footer .container a:hover,
footer .container a:active {
  color: var(--link-text-hover-color);
}

@media (min-width: 1280px) {
  footer .container {
    flex-direction: row;
  }
}
