header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
}

header .container {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  gap: 1rem;
}

header .top {
  width: 100%;
  position: relative;
  transition: 0.4s ease-in-out;
}

header .menu {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-items: center;
  display: none;
  z-index: 10;
}

header .links .icon {
  display: none;
}

.header .links {
  filter: drop-shadow(0 0 2px rgb(0 0 0 / 0.4));
}

.header .logo {
  height: 8rem;
  filter: drop-shadow(0 0 2px rgb(0 0 0 / 0.4));
}

.header-scroll .menu {
  display: flex;
}

.header-scroll .logo {
  height: 6rem;
  display: flex;
  justify-content: center;
}

.header-scroll {
  background-color: var(--header-background-color);
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
}

/* Submenu */
header .submenu {
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  z-index: 2;
  color: var(--text-color);
  background-color: var(--background-color);
  filter: drop-shadow(0 0 2px rgb(0 0 0 / 0.4));
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
}

header .submenu a {
  color: var(--text-color);
}

header .submenu .list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 1rem;
  margin: 0;
  padding: 0;
  justify-content: center;
  flex-grow: 1;
}

header .submenu ul li {
  font-size: 4rem;
  text-align: center;
}

header .submenu .lang {
  font-size: 2rem;
  margin: 4rem 0;
  text-align: center;
}

header .submenu .icons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  font-size: 2rem;
  margin: 1.4rem 0;
}

/* Colors */
.header .container a {
  color: #fff !important;
}

.header-scroll .container a {
  color: var(--list-link-color);
}

.header nav ul li a::before {
  background-color: #fff;
}

/* Menu Icon */
.menu-icon {
  width: 24px;
  height: 14px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.menu-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: var(--list-link-color);
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.menu-icon span:nth-child(1) {
  top: 0px;
}

.menu-icon span:nth-child(2),
.menu-icon span:nth-child(3) {
  top: 6px;
}

.menu-icon span:nth-child(4) {
  top: 12px;
}

.menu-icon.open span:nth-child(1) {
  top: 6px;
  width: 0%;
  left: 50%;
}

.menu-icon.open span:nth-child(2) {
  transform: rotate(45deg);
}

.menu-icon.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.menu-icon.open span:nth-child(4) {
  top: 6px;
  width: 0%;
  left: 50%;
}

@media (min-width: 1280px) {
  header .container {
    gap: 2rem;
    flex-direction: row;
  }

  header .top {
    width: unset;
  }

  header .top .menu {
    display: none;
  }

  header .links .icon {
    display: unset;
  }

  header .icon a:last-child {
    display: none !important;
  }

  header .links .icon:hover a {
    display: block !important;
  }

  header .links .icon:hover a:first-child {
    display: none !important;
  }
}
