.quote {
  text-align: center;
  padding: 4rem 0;
  background-color: var(--accent-color);
}

.quote h1 {
  padding-top: 2rem;
}

.quote p {
  font-size: 2.6rem;
  line-height: 1.5;
  font-style: italic;
  padding: 2rem;
}
