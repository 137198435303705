/* Default colors */
body {
  --background-color: #fff;
  --primary-color: #fff;
  --secondary-color: #ba8aa3;
  --accent-color: #edf1f5;
  --element-color: #1e1e1e;
  --border-color: #000;

  --header-background-color: rgba(255, 255, 255, 0.8);

  --text-color: #5e5d5b;
  --text-primary-color: #e74c3c;
  --text-accent-color: #211f1c;
  --text-shadow: rgba(255, 255, 255, 0.645);

  --button-color: #d7a8a3;
  --button-hover-color: #d7a8a3;

  --list-link-color: #333;
  --link-text-color: #939393;
  --link-text-hover-color: var(--secondary-color);
  --link-text-hover-color: var(--secondary-color);

  --footer-background: #cdd6de;
}

/* Dark theme colors */
body.theme-dark {
  --background-color: #121212;
  --primary-color: #0c0c0c;
  --accent-color: #0d0e12;
  --border-color: #040403;

  --header-background-color: rgba(0, 0, 0, 0.8);

  --text-color: #fff;
  --text-primary-color: #d7a8a3;
  --text-accent-color: #d3d3d3;
  --text-shadow: rgba(0, 0, 0, 0.645);

  --button-color: #d7a8a3;
  --button-hover-color: #d7a8a3;

  --list-link-color: #d3d3d3;
  --link-text-color: #939393;

  --footer-background: #000;
}
