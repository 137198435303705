:root {
  --spacing-horizontal: 2rem;

  --breakpoint-tablet: 640px;
  --breakpoint-laptop: 1024px;
  --breakpoint-desktop: 1280px;
}

/* Tablet */
@media (min-width: 640px) {
  :root {
    --spacing-horizontal: 4rem;
  }
}

/* Desktop */
@media (min-width: 1400px) {
  :root {
    --spacing-horizontal: 8rem;
  }
}
