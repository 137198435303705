.socials-list {
  display: flex;
  list-style-type: none;
  justify-content: center;
  gap: 4rem;
}

.socials-list li a svg {
  width: 3rem;
}

@media (min-width: 1280px) {
  .socials-list {
    gap: 2rem;
  }
  .socials-list li a svg {
    width: 1.5rem;
  }
}
