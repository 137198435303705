.hero-section {
  height: 100vh;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background-color: var(--background-color);
}

.hero-section .name {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
  padding: 1rem;
}

.hero-section .name .title {
  text-shadow: 2px 2px var(--text-shadow);
}

.hero-section .name h1 {
  font-size: 5rem;
}

.hero-section .name h2 {
  font-size: 4rem;
}

.hero-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  inset: 0;
}

/* Tablet */
@media (min-width: 640px) {
}

/* Desktop */
@media (min-width: 1400px) {
  .hero-section .name {
    justify-content: start;
    align-items: start;
    text-align: start;
  }

  .hero-section .name h1 {
    font-size: 7rem;
  }

  .hero-section .name h2 {
    font-size: 5.5rem;
  }
}
