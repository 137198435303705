.timeline {
  padding: 4rem 0;
}

.timeline .title {
  text-align: center;
  padding: 2rem 0;
}

.timeline .list ul {
  position: relative;
}

.timeline .list ul::before {
  left: calc(50% - 1px);
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #e4e4e4;
  z-index: -1;
}

.timeline .list ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  text-align: center;
}

.timeline .list ul li:last-child {
  padding-bottom: 4rem;
}

.timeline .list ul li::before {
  content: "";
  flex: 1;
}

.timeline .list .image {
  width: 12rem;
  padding: 0 2rem;
}

.timeline .list .image img {
  border-radius: 100%;
  border: 0.6rem solid var(--background-color);
}

.timeline .list .text {
  background-color: var(--background-color);
  margin-top: 1rem;
  padding: 1rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: none;
}

.timeline .list .skills {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.timeline .list .footer {
  margin: 1rem 0;
  text-align: center;
  white-space: pre-line;
  text-transform: none;
}

@media (min-width: 1280px) {
  .timeline .list .text {
    background-color: unset;
  }

  .timeline .list ul li {
    align-items: start;
  }

  .timeline .list ul li:nth-child(even) {
    flex-direction: row;
    text-align: left;
  }

  .timeline .list ul li:nth-child(even) .skills {
    justify-content: flex-start;
  }

  .timeline .list ul li:nth-child(odd) .skills {
    justify-content: flex-end;
  }

  .timeline .list ul li:nth-child(odd) {
    flex-direction: row-reverse;
    text-align: right;
  }
}
