.fadeInLoadUp {
  animation-name: fadeInLoadUpAnimation;
  animation-duration: 0.8s;
}

@keyframes fadeInLoadUpAnimation {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.fadeInLoad {
  animation-name: fadeInLoadAnimation;
  animation-duration: 0.8s;
}

@keyframes fadeInLoadAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.slide-in {
  animation: slideInAnimation 0.5s forwards;
}

.slide-out {
  animation: slideInAnimation 0.5s forwards;
}

@keyframes slideInAnimation {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

.fadeInOnce {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  background-color: var(--background-color);
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeOut {
  animation-name: fadeOut;
  animation-duration: 2s;
  animation-fill-mode: both;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
