@import "./styles/reset.css";
@import "./styles/fonts.css";
@import "./styles/theme.css";
@import "./styles/variables.css";
@import "./styles/animations.css";

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 200;
  fill: currentColor;
  color: var(--text-color);
  background-color: var(--background-color);
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  transition: background-color 1s ease, color 0.8s ease;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: var(--link-text-color);
}

a:hover,
a:focus {
  color: var(--link-text-hover-color);
}

svg {
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  transition: none;
}

svg * {
  transition: none;
}

/* Main */
main {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}

section {
  min-height: 200px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
  font-weight: 500;
  padding: 0;
  margin: 0;
  font-family: "Lexend", sans-serif;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3.25rem;
}

h3 {
  font-size: 2.75rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.75rem;
}

h6 {
  font-size: 1.5rem;
}

p {
  font-size: 1.6rem;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding-left: var(--spacing-horizontal);
  padding-right: var(--spacing-horizontal);
}

.title {
  color: var(--text-accent-color);
  text-shadow: 2px 2px var(--secondary-color);
}

.links ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
}

.links ul li {
  margin: 0 0.5rem;
  flex-shrink: 0;
}

.links ul li a {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  text-align: center;
  padding: 0.5rem;
  color: var(--list-link-color);
}

.links ul li a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out 0s;
}

.links ul li a::before {
  visibility: hidden;
  transform: scaleX(0);
  background-color: var(--list-link-color);
}

.links ul li a.active::before {
  visibility: visible;
  transform: scaleX(1);
}

.skills .skill {
  border-radius: 4rem;
  border: 0.1rem solid var(--border-color);
  background-color: var(--element-color);
  padding: 0.2rem 1rem;
  cursor: default;
  color: #fff;
}

/* Tablet */
@media (min-width: 640px) {
  .links ul li {
    margin: 0 1rem;
  }
}

/* Desktop */
@media (min-width: 1400px) {
  .container {
    width: 1280px;
  }

  .links ul li {
    margin: 0 2rem;
  }
}

@media (hover: hover) {
  .links ul li a:hover::before {
    visibility: visible;
    transform: scaleX(1);
  }
}
